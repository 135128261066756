.registryContainer {
    width: 100vw;
    height: 60vh;
    position: relative;
    top: 30vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    font-family: 'Dancing Script', cursive;
}

a {
    color: black;
}

@media (max-width: 768px) {
    .registryContainer {
        height: 100vh;
        top: 0;
    }
}