.MuiButtonBase-root.MuiFab-root {
    background-color: #8ABD91 !important;
}

.MuiRadio-root.Mui-checked {
    color: #8ABD91 !important;
}

.MuiSwitch-switchBase.MuiSwitch-colorPrimary {
    color: #8ABD91 !important;
}
.MuiSwitch-switchBase.MuiSwitch-colorSecondary {
    color: #8ABD91 !important;
}

.MuiSwitch-track {    
    background-color: #8ABD91 !important;
}

.MuiFormLabel-root.MuiFormLabel-colorSecondary {
    color: #8ABD91 !important;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary {
    color: #8ABD91 !important;
}

.MuiFormLabel-root.MuiInputLabel-root {
    color: #8ABD91 !important;
}

fieldset {
    border-color: #8ABD91 !important;

}

.MuiCheckbox-root.MuiCheckbox-colorSecondary {
    color: #8ABD91 !important;
}
    




@media screen and (max-width: 1500px) and (min-width: 600px) {
    
    .rsvpForm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 100px;
        position: absolute;
    }

}

@media screen and (max-width: 599px) {
    .rsvpForm {
        top: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.MuiFormControl-root {
    padding-bottom: 10px !important;
}

.notAttending {
        display: flex;
        flex-direction: column;
        justify-content: center;
    
}

.rsvp_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

